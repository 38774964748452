import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import { transformPostSchema } from '../util';
import HeaderMeta from '../components/HeaderMeta';

import {
  BlogPostHeader,
  BlogPostBody,
  FlexRow,
  Button,
  ResponsiveMaxWidthFrame,
  SectionGroup,
  HeadingBox,
} from "@minware/ui-minware";

export const BlogPostTemplate = ({
  content,
  post,
}) => {
  const postNew = transformPostSchema(post);
  const canonicalUrl = postNew.postLink;
  const { imageLink } = postNew;

  const headerWithCanon = {
    ...content,
    imageLink,
    canonicalUrl,
    title: `${content.title} | minware`,
    ogType: 'article',
  };

  return (
    <Layout desktopBgImageName="none">
      <HeaderMeta header={headerWithCanon}/>

      <BlogPostHeader {...postNew} featured="true" backLink="/blog" backText="All Posts"/>

      <BlogPostBody
        {...postNew}
        backLink="/blog"
        backText="All Posts"
        shareUrl={`https://www.minware.com${canonicalUrl}`}
      />

      <ResponsiveMaxWidthFrame maxWidth="600px" spacing={20}>
        <SectionGroup>
          <HeadingBox level="h1" tagLevel="h2" align="center" justifyContent="center">
            Try minware today
          </HeadingBox>
        </SectionGroup>
        <FlexRow gap={20} rowGap={0} justifyContent="center">
          <Button cta link="/docs/demo">
            Self-Guided Demo
          </Button>
          <Button ctaSecondary link="/contact?from=blog">
            Email/Talk to Us
          </Button>
        </FlexRow>
      </ResponsiveMaxWidthFrame>
    </Layout>
  )
}

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
}

const BlogPost = ({ data }) => {
  const { index, post } = data;

  const content = post.frontmatter;
  const octopus = index.frontmatter.octopus;

  return (
    <BlogPostTemplate
      content={content}
      octopus={octopus}
      post={post}
    />
  )
}

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default BlogPost

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    index: markdownRemark(frontmatter: {templateKey: {eq: "index-page"}}) {
      ...OctopusFragment
    }
    post: markdownRemark(id: { eq: $id }) {
      ...BlogPostFragment
    }
  }
`
